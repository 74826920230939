/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.App {
	text-align: center;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.full-window-div {
	height: "100vh";
}

.file-input {
	display: none;
}

.file-picker {
	font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
	padding: 10px;
	border-radius: 5px;
	border: 1px #404040;
	cursor: pointer;
	color: #ededed;
	display: inline-block;
	background-color: #404040;
	box-shadow: 12px 5px 30px -12px rgba(0, 0, 0, 0.58);
}

.file-picker:hover {
	color: white;
	background-color: #252525;
}

.Auth-form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 90vh;
}

.Auth-form {
	width: 420px;
	box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
	padding-top: 30px;
	padding-bottom: 20px;
	border-radius: 8px;
	background-color: white;
}

.Auth-form-content {
	padding-left: 12%;
	padding-right: 12%;
}

.Auth-form-title {
	text-align: center;
	margin-bottom: 1em;
	font-size: 24px;
	color: rgb(34, 34, 34);
	font-weight: 800;
}

label {
	font-size: 14px;
	font-weight: 600;
	color: rgb(34, 34, 34);
}

